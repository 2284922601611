import { UniversalHeader as StandaloneHeader } from '@amzn/sitc-frontend/components';
import React from 'react';

import { paths } from '../constants/paths';

type Props = {
  basePath: string;
};

export const UniversalHeader = ({ basePath }: Props) => {
  // Remove '/' from standlone mode as it's already in "paths" and would cause '//' if not removed.
  // Integrated mode has basePath as /app-name, so it's fine to leave it as is.
  const basePathPrefix = basePath === '/' ? '' : `${basePath}`;

  return (
    // Use minimal Universal Header.
    <StandaloneHeader
      disableWindowScrollElevation
      productIdentifier="Studios Research"
      productIdentifierPath={basePath}
      headerLinks={[
        { primary: 'User Lookup', path: `${basePathPrefix}${paths.userLookup}` },
        { primary: 'Survey Calendar', path: `${basePathPrefix}${paths.survey}` },
        { primary: 'Incentive Survey', path: `${basePathPrefix}${paths.incentiveSurvey}` },
      ]}
    />
  );
};
