import { ErrorBoundary, StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import StudiosResearchApi from './components/StudiosResearchApi';

const configuration = {};
const rootComponent = process.env.STANDALONE
  ? () => <StandaloneConfiguration RootComponent={StudiosResearchApi} configuration={configuration} />
  : StudiosResearchApi;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error, info: React.ErrorInfo) => (
    <ThemeProvider theme={backlotLightTheme}>
      <CssBaseline />
      <ErrorBoundary error={error} info={info} />
    </ThemeProvider>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
