// We don't have a dev graphql endpoint since we don't have DynamoDB table set up in dev

const beta = {
  graphql: 'https://34lqiqcl2vgbfmjuvpruwkuhna.appsync-api.us-east-1.amazonaws.com/graphql',
};

const prod = {
  graphql: 'https://pyr727j4qrcnvc57x3ewfg4x6q.appsync-api.us-east-1.amazonaws.com/graphql',
};

export const getGraphqlEndpoint = (stage: string) => {
  if (stage === 'beta' || stage === 'dev') {
    return beta.graphql;
  }
  return prod.graphql;
};
